import * as React from 'react';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export interface MultiSelectOption {
    id: number;
    data: string;
}

function CustomEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field, options } = props;
    const apiRef = useGridApiContext();

    const handleChange = (event: any) => {
        const eventValue = event.target.value as MultiSelectOption[];
        const newVaue = getValue(eventValue);
        apiRef.current.setEditCellValue({
            id,
            field,
            value: newVaue.filter((x: MultiSelectOption) => x.data !== ''),
        });
    };

    const getValue = (
        value:
            | string
            | string[]
            | null
            | undefined
            | MultiSelectOption
            | MultiSelectOption[]
    ): MultiSelectOption[] => {
        if (Array.isArray(value)) {
            return value.map((item) => {
                if (typeof item === 'string') {
                    return { id: 0, data: item };
                } else {
                    return {
                        id: item.id,
                        data: item.data,
                    };
                }
            });
        }

        if (typeof value === 'string') {
            return [{ id: 0, data: value }];
        }

        return [];
    };

    return (
        <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={getValue(value)?.map((x) => x.data)}
            onChange={handleChange}
            sx={{ width: '100%' }}>
            {options.map((option: MultiSelectOption) => {
                return (
                    <MenuItem key={option.data} value={option.data}>
                        {option.data}
                    </MenuItem>
                );
            })}
        </Select>
    );
}

export const MultiSelectEditCell = (
    params: GridRenderEditCellParams,
    options: MultiSelectOption[]
) => <CustomEditComponent {...params} options={options} />;
