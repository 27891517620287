import React, { useEffect, useState } from 'react';
import BootstrapTable, {
    SortOrder,
} from '../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import { useDispatch } from 'react-redux';
import { useStateSelector } from '../../../store/selectors';
import { Badge, Input } from 'reactstrap';
import DataFormQuestion from '../../../models/DataRequestHub/DataFormQuestion';
import {
    QuestionProgressStatus,
    QuestionProgressStatusList,
} from '../../../models/DataRequestHub/QuestionProgressStatusEnum';
import ReactSwitch from 'react-switch';
import SortingOption from '../../../components/Table/models/bootstrap-table-sorting-option';
import { sortingFunction } from '../../../components/Table/models/table-helper';
import { ExpandedText } from '../ExpandedText/ExpandedText';
import createNotification from '../../../utils/createNotification';
import { FetchSummary } from '../../../slices/user-summary/fetch-summary.thunk';
import './QuestionnaireForm.scss';
import { TextAnswerField } from './AnswerFields/TextAnswerField';
import { NumericAnswerField } from './AnswerFields/NumericAnswerField';
import { BooleanAnswerField } from './AnswerFields/BooleanAnswerField';
import {
    AnswerTypeVariable,
    AnswerTypeVariableNamesList,
    FormTypeVariable,
    FormTypeVariableNamesList,
    ProjectVariable,
} from '../../../models/DataRequestHub/ProjectVariable';
import { DropDownAnswerField } from './AnswerFields/DropDownAnswerField';
import moment from 'moment';
import QuestionSeenStatus from '../../../models/DataRequestHub/QuestionSeenStatus';
import {
    addQuestionAnswer,
    ResetQuestionInfo,
    resetQuestionsSeenStatusByFormId,
    resetQuestionsSeenStatusByIds,
} from '../../../models/DataRequestHub/slices/data-request-notifications.slice';
import Answer from '../../../models/DataRequestHub/Answer';
import { DateAnswerField } from './AnswerFields/DateAnswerField';
import { DataTableFormPopup } from '../DataTableFormPopup/DataTableFormPopup';
import { DataRequestProject } from '../../../models/DataRequestHub/DataRequestProject';

interface QuestionnaireFormProps {
    projectId: number;
    questionnaireFormId: number;
    visibleQuestionnaireQuestions: DataFormQuestion[];
    isAdminView: boolean;
    isComplete: boolean;
    updateProjectsData(isInitialFetch?: boolean): void;
    projectVariables: ProjectVariable[];
    questionsSeenStatus: QuestionSeenStatus[];
    project: DataRequestProject;
}

interface TableSortingState {
    data: DataFormQuestion[];
    sortField: string;
    sortOrder: string;
}

interface FormQuestionsView extends DataFormQuestion {
    subQuestions: DataFormQuestion[];
    updateTrigger: Date;
}

const QuestionnaireForm = (props: QuestionnaireFormProps) => {
    const axios = useStateSelector((s) => s.core.axios);
    const [adminFeedback, setAdminFeedback] = useState<string>('');
    const [userQuestion, setUserQuestion] = useState<string>('');
    const dispatch = useDispatch();
    const [fieldBlurTrigger, setFieldBlurTrigger] = useState<boolean>(false);
    const [selectedQuestionId, setSelectedQuestionId] = useState<number>(0);
    const largeTextAreaHeightPx = 130;
    const mediumTextAreaHeightPx = 62;
    const smallTextAreaHeightPx = 37;
    const [sortingOption, setSortingOption] = useState<SortingOption>({
        dataField: 'displayOrder',
        order: 'asc',
    });
    const [tableData, setTableData] = useState<DataFormQuestion[]>([]);
    const [isNewForm, setIsNewForm] = useState(
        props.questionsSeenStatus.every((q) => q.isNewForm)
    );
    const [isTableViewPopupVisible, setIsTableViewPopupVisible] =
        useState(false);
    const [selectedTableViewId, setSelectedTableViewId] = useState(0);
    useEffect(() => {
        setIsNewForm(props.questionsSeenStatus.every((q) => q.isNewForm));
    }, [props.questionsSeenStatus]);

    useEffect(() => {
        if (props.visibleQuestionnaireQuestions) {
            const questions = props.visibleQuestionnaireQuestions;
            const tableData = questions
                .filter((question) => !question.isSubQuestion)
                .map((question): FormQuestionsView => {
                    const subQuestions = questions.filter(
                        (subQuestion) =>
                            subQuestion.isSubQuestion &&
                            subQuestion.originQuestionId === question.id &&
                            subQuestion.originQuestionId &&
                            (subQuestion.isVisible || props.isAdminView)
                    );
                    return {
                        ...question,
                        subQuestions: subQuestions,
                        updateTrigger: new Date(),
                    };
                });
            sortFunction(tableData, sortingOption);
        }
    }, [props.visibleQuestionnaireQuestions, props.questionsSeenStatus]);

    const resetNotifications = (resetQuestionInfos: ResetQuestionInfo[]) => {
        if (!isNewForm) {
            dispatch(
                resetQuestionsSeenStatusByIds({
                    resetQuestionInfo: resetQuestionInfos,
                    formId: props.questionnaireFormId,
                })
            );
        } else {
            dispatch(
                resetQuestionsSeenStatusByFormId({
                    formId: props.questionnaireFormId,
                })
            );
        }
    };

    useEffect(() => {
        if (fieldBlurTrigger) {
            if (props.isAdminView) {
                handleAdminFeedbackChange(adminFeedback, selectedQuestionId);
            } else {
                handleUserQuestionChange(userQuestion, selectedQuestionId);
            }
            setFieldBlurTrigger(false);
        }
    }, [fieldBlurTrigger]);

    const handleAdminFeedbackChange = (
        feedback: string,
        questionId: number
    ) => {
        const existedFeedback = props.visibleQuestionnaireQuestions.find(
            (f) => f.id === questionId
        ).adminFeedback;

        if (existedFeedback != feedback) {
            const requestObj = {
                adminFeedback: feedback,
            };

            resetNotifications([
                {
                    questionId: questionId,
                    isSubquestion: false,
                },
            ]);

            axios
                .put(
                    `/api/DataRequestProject/${props.projectId}/DataForm/${props.questionnaireFormId}/Questions/${questionId}/AdminFeedback`,
                    requestObj
                )
                .then((response) => {
                    if (response.status === 200) {
                        props.updateProjectsData();
                    } else {
                        createNotification(
                            'An error occured while saving comment',
                            'error'
                        );
                    }
                })
                .finally();
        }
    };

    const updateNotification = (questionId: number) => {
        const targetQuestion = props.visibleQuestionnaireQuestions.find(
            (f) => f.id === questionId
        );
        resetNotifications([
            {
                questionId: questionId,
                isSubquestion: targetQuestion.isSubQuestion,
            },
        ]);
    };

    const handleUserAnswer = (questionId: number, userAnswer: string) => {
        const targetQuestion = props.visibleQuestionnaireQuestions.find(
            (f) => f.id === questionId
        );
        const existedAnswerText = targetQuestion?.answer?.answerText ?? '';
        resetNotifications([
            {
                questionId: questionId,
                isSubquestion: false,
            },
        ]);
        if (existedAnswerText !== userAnswer) {
            const requestObj = {
                answerText: userAnswer,
            };

            const answerObject: Answer = {
                id: 0,
                answerFiles: [],
                answerText: userAnswer,
            };
            targetQuestion.answer = answerObject;
            dispatch(
                addQuestionAnswer({
                    answer: answerObject,
                    questionInfo: {
                        questionId: targetQuestion.id,
                        isSubquestion: targetQuestion.isSubQuestion,
                    },
                })
            );

            const requestUrl = targetQuestion.isSubQuestion
                ? `/api/dataRequestProject/${props.projectId}/dataForm/${props.questionnaireFormId}/questions/${targetQuestion.originQuestionId}/subQuestions/${questionId}/answer`
                : `/api/DataRequestProject/${props.projectId}/DataForm/${props.questionnaireFormId}/Questions/${questionId}/Answer`;

            axios
                .put(requestUrl, requestObj)
                .then((response) => {
                    if (response.status === 200) {
                        props.updateProjectsData();
                        dispatch(FetchSummary() as any);
                    } else {
                        createNotification(
                            'An error occured while saving question',
                            'error'
                        );
                    }
                })
                .finally(() => {});
        }
    };

    const handleUserQuestionChange = (question: string, questionId: number) => {
        const existedFeedback = props.visibleQuestionnaireQuestions.find(
            (f) => f.id === questionId
        ).userQuestion;

        if (existedFeedback != question) {
            const requestObj = {
                userQuestion: question,
            };

            resetNotifications([
                {
                    questionId: questionId,
                    isSubquestion: false,
                },
            ]);

            axios
                .put(
                    `/api/DataRequestProject/${props.projectId}/DataForm/${props.questionnaireFormId}/Questions/${questionId}/UserFeedback`,
                    requestObj
                )
                .then((response) => {
                    if (response.status === 200) {
                        props.updateProjectsData();
                        dispatch(FetchSummary() as any);
                    } else {
                        createNotification(
                            'An error occured while saving question',
                            'error'
                        );
                    }
                })
                .finally();
        }
    };

    const handleTableChange = (_props: any, state: TableSortingState) => {
        const newSortingOption: SortingOption = {
            dataField: state.sortField,
            order: state.sortOrder as SortOrder,
        };
        setSortingOption(newSortingOption);
        sortFunction(state.data, newSortingOption);
    };

    const sortFunction = (
        data: DataFormQuestion[],
        sortOption: SortingOption = sortingOption
    ) => {
        const sortedQuestions = sortingFunction(data, sortOption);
        setTableData(sortedQuestions);
    };

    const handleCompleteStatusChange = (
        isMarkedAsComplete: boolean,
        questionId: number
    ) => {
        const requestObj = {
            isMarkedAsComplete: isMarkedAsComplete,
        };

        axios
            .put(
                `/api/DataRequestProject/${props.projectId}/DataForm/${props.questionnaireFormId}/Questions/${questionId}/MarkedAsCompleteStatus`,
                requestObj
            )
            .then((response) => {
                if (response.status === 200) {
                    props.updateProjectsData();
                } else {
                    createNotification(
                        'An error occured while changing marked as complete status',
                        'error'
                    );
                }
            });
    };

    const handleQuestionVisibilityStatusChange = (
        isVisible: boolean,
        questionId: number
    ) => {
        const requestObj = {
            isVisible: isVisible,
        };
        axios
            .put(
                `/api/DataRequestProject/${props.projectId}/DataForm/${props.questionnaireFormId}/Questions/${questionId}/VisibleStatus`,
                requestObj
            )
            .then((response) => {
                if (response.status === 200) {
                    props.updateProjectsData();
                } else {
                    createNotification(
                        'An error occured while changing visibility status',
                        'error'
                    );
                }
            });
    };

    const handleSubQuestionVisibilityStatusChange = (
        isVisible: boolean,
        questionId: number,
        subQuestionId: number
    ) => {
        const requestObj = {
            isVisible: isVisible,
        };

        axios
            .put(
                `/api/DataRequestProject/${props.projectId}/DataForm/${props.questionnaireFormId}/Questions/${questionId}/SubQuestions/${subQuestionId}/VisibleStatus`,
                requestObj
            )
            .then((response) => {
                if (response.status === 200) {
                    props.updateProjectsData();
                } else {
                    createNotification(
                        'An error occured while changing visibility status',
                        'error'
                    );
                }
            });
    };

    const handleQuestionStatusChange = (
        status: QuestionProgressStatus,
        questionId: number
    ) => {
        const requestObj = {
            status: status,
        };

        axios
            .put(
                `/api/DataRequestProject/${props.projectId}/DataForm/${props.questionnaireFormId}/Questions/${questionId}/Status`,
                requestObj
            )
            .then((response) => {
                if (response.status === 200) {
                    props.updateProjectsData();
                } else {
                    createNotification(
                        'An error occured while changing status',
                        'error'
                    );
                }
            });
    };

    const extendTextAreaHeight = (
        element: any,
        content: string,
        heightPx: number = mediumTextAreaHeightPx
    ) => {
        const heightInNumber = Number(
            element.target.style.height.replace(/[^0-9]/g, '')
        );
        const isContentEmpty = !content;
        if (
            (heightInNumber != 0 || !isContentEmpty) &&
            heightPx < element.target.scrollHeight
        ) {
            element.target.style.height = element.target.scrollHeight + 'px';
        } else {
            element.target.style.height = heightPx + 'px';
        }
    };

    const decreaseTextAreaHeight = (
        element: any,
        heightPx: number = mediumTextAreaHeightPx
    ) => {
        element.target.style.height = heightPx + 'px';
    };

    const getTextAreaHeightByTextSizeOption = (textSize: string) => {
        switch (textSize) {
            case 'Small':
                return smallTextAreaHeightPx;
            case 'Large':
                return largeTextAreaHeightPx;
        }
        return mediumTextAreaHeightPx;
    };

    const renderDateAnswerField = (row: DataFormQuestion) => {
        return (
            <DateAnswerField
                question={row}
                isAdminView={props.isAdminView}
                isReadonly={props.isComplete}
                updateNotification={updateNotification}
                updateDateAnswer={handleUserAnswer}></DateAnswerField>
        );
    };

    const renderFormAnswerField = (row: DataFormQuestion) => {
        switch (row.formType) {
            case FormTypeVariableNamesList.find(
                (x) => x.id === FormTypeVariable.YesNo
            ).name:
                return (
                    <BooleanAnswerField
                        question={row}
                        updateNotification={updateNotification}
                        isAdminView={props.isAdminView}
                        isReadonly={props.isComplete}
                        updateBooleanAnswer={
                            handleUserAnswer
                        }></BooleanAnswerField>
                );
            case FormTypeVariableNamesList.find(
                (x) => x.id === FormTypeVariable.DropDown
            ).name:
                return (
                    <DropDownAnswerField
                        variable={props.projectVariables.find(
                            (x) => x.name === row.formList
                        )}
                        updateNotification={updateNotification}
                        question={row}
                        isAdminView={props.isAdminView}
                        isReadonly={props.isComplete}
                        updateBooleanAnswer={
                            handleUserAnswer
                        }></DropDownAnswerField>
                );
        }
    };

    const renderAnswerField = (row: DataFormQuestion) => {
        switch (row.answerType) {
            case AnswerTypeVariableNamesList.find(
                (x) => x.id === AnswerTypeVariable.Text
            ).name:
                return (
                    <TextAnswerField
                        question={row}
                        isAdminView={props.isAdminView}
                        isReadonly={props.isComplete}
                        getTextAreaHeightByTextSizeOption={
                            getTextAreaHeightByTextSizeOption
                        }
                        updateNotification={updateNotification}
                        extendTextAreaHeight={extendTextAreaHeight}
                        decreaseTextAreaHeight={decreaseTextAreaHeight}
                        updateTextAnswer={handleUserAnswer}></TextAnswerField>
                );
            case AnswerTypeVariableNamesList.find(
                (x) => x.id === AnswerTypeVariable.Number
            ).name:
                return (
                    <NumericAnswerField
                        question={row}
                        updateNotification={updateNotification}
                        isAdminView={props.isAdminView}
                        isReadonly={props.isComplete}
                        updateNumericAnswer={
                            handleUserAnswer
                        }></NumericAnswerField>
                );
            case AnswerTypeVariableNamesList.find(
                (x) => x.id === AnswerTypeVariable.Form
            ).name:
                return renderFormAnswerField(row);
            case AnswerTypeVariableNamesList.find(
                (x) => x.id === AnswerTypeVariable.Date
            ).name:
                return renderDateAnswerField(row);
        }
        return mediumTextAreaHeightPx;
    };

    const renderSubQuestionList = (
        subQuestions: DataFormQuestion[],
        isQuestionNew: boolean
    ) => {
        return (
            <ol type="a" className="subquestions-list">
                {subQuestions
                    .sort(
                        (firstSubQuestion, secondSubQuestion) =>
                            firstSubQuestion.displayOrderSub -
                            secondSubQuestion.displayOrderSub
                    )
                    .map((subquestion) => {
                        const questionSeenStatus =
                            props.questionsSeenStatus.find(
                                (f) =>
                                    f.id === subquestion.id && f.isSubQuestion
                            );
                        return (
                            <li
                                className="sub-question-item"
                                key={subquestion.id}>
                                <div className="item-question-wrapper">
                                    <div>
                                        {subquestion.tableViewId != null &&
                                        subquestion.tableViewId > 0
                                            ? getStringWithLink(
                                                  subquestion.displayQuestion,
                                                  subquestion.tableViewId
                                              )
                                            : subquestion.displayQuestion}
                                    </div>
                                    {!props.isAdminView &&
                                    !questionSeenStatus?.isSeenByUser &&
                                    !questionSeenStatus?.isNewProject &&
                                    !questionSeenStatus?.isNewForm &&
                                    !isQuestionNew ? (
                                        <Badge className="unread-comments-badge">
                                            {'1'}
                                        </Badge>
                                    ) : (
                                        []
                                    )}
                                </div>
                                {renderAnswerField(subquestion)}
                            </li>
                        );
                    })}
            </ol>
        );
    };

    const renderQuestionSwitcher = (isVisible: boolean, questionId: number) => (
        <ReactSwitch
            offColor="#d92550"
            checked={isVisible}
            onChange={(event) => {
                resetNotifications([
                    {
                        questionId: questionId,
                        isSubquestion: false,
                    },
                ]);
                handleQuestionVisibilityStatusChange(event, questionId);
            }}
            height={22}
        />
    );

    const renderSubQuestionSwitcher = (
        isVisible: boolean,
        questionId: number,
        subQuestionId: number
    ) => (
        <ReactSwitch
            offColor="#d92550"
            checked={isVisible}
            onChange={(event) => {
                resetNotifications([
                    {
                        questionId: questionId,
                        isSubquestion: true,
                    },
                ]);
                handleSubQuestionVisibilityStatusChange(
                    event,
                    questionId,
                    subQuestionId
                );
            }}
            height={22}
        />
    );

    const renderQuestionVisibilitySwitcherCell = (
        cellContent: boolean,
        question: FormQuestionsView
    ) => {
        if (question.subQuestions?.length) {
            return (
                <>
                    <div>
                        <div>Question:</div>
                        <div className="visibility-swithcer">
                            {renderQuestionSwitcher(cellContent, question.id)}
                        </div>
                    </div>
                    <div className="subquestions-visibility-list">
                        <label>Sub Questions: </label>
                        <ol type="a">
                            {question.subQuestions.map((subQuestion) => {
                                return (
                                    <li>
                                        {renderSubQuestionSwitcher(
                                            subQuestion.isVisible,
                                            question.id,
                                            subQuestion.id
                                        )}
                                    </li>
                                );
                            })}
                        </ol>
                    </div>
                </>
            );
        }
        return (
            <div className="visibility-swithcer">
                {renderQuestionSwitcher(cellContent, question.id)}
            </div>
        );
    };

    const getStringWithLink = (content: string, tableViewId: number) => {
        const regex = /\[\s*([^\]]+?)\s*\]/;
        const elements = [];
        const match = regex.exec(content);

        if (match) {
            elements.push(content.slice(0, match.index));
            elements.push(
                <span
                    key={match.index}
                    className="link-button"
                    onClick={() => {
                        setIsTableViewPopupVisible(true);
                        setSelectedTableViewId(tableViewId);
                    }}>
                    {match[0].replace('[', '').replace(']', '')}
                </span>
            );
            elements.push(content.slice(match.index + match[0].length));
        } else {
            elements.push(content);
        }

        return <>{elements}</>;
    };

    const getColumns = () => [
        { dataField: 'id', text: '', hidden: true },
        {
            dataField: 'isMarkedAsComplete',
            text: 'Marked as complete',
            headerClasses: 'completness-checkbox-header',
            sort: false,
            hidden: !props.isAdminView,
            formatter: (cellContent: boolean, row: DataFormQuestion) => {
                const questionSeenStatus = props.questionsSeenStatus.find(
                    (f) => f.id === row.id && !f.isSubQuestion
                );

                return (
                    <>
                        <div className="checkbox-field admin-checkbox-field">
                            <Input
                                checked={cellContent}
                                type="checkbox"
                                onChange={(event) => {
                                    resetNotifications([
                                        {
                                            questionId: row.id,
                                            isSubquestion: false,
                                        },
                                    ]);
                                    handleCompleteStatusChange(
                                        event.target.checked,
                                        row.id
                                    );
                                }}
                                className="checkbox-input complete-checkbox"
                            />

                            {questionSeenStatus?.status ===
                                QuestionProgressStatus.InReview &&
                            !questionSeenStatus?.isSeenByAdmin &&
                            row.isMarkedAsComplete ? (
                                <Badge className="unread-comments-badge in-review-notification-badge">
                                    {'1'}
                                </Badge>
                            ) : (
                                []
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: 'isVisible',
            text: 'Visible',
            hidden: !props.isAdminView,
            sort: true,
            formatter: (cellContent: boolean, row: FormQuestionsView) =>
                renderQuestionVisibilitySwitcherCell(cellContent, row),
        },
        {
            dataField: 'status',
            text: 'Status',
            style: { minWidth: '150px' },

            hidden: !props.isAdminView,
            sort: true,
            formatter: (
                cellContent: QuestionProgressStatus,
                row: DataFormQuestion
            ) => {
                return (
                    <select
                        defaultValue={cellContent}
                        style={{
                            color: QuestionProgressStatusList.find(
                                (x) => x.id == cellContent
                            ).color,
                            fontFamily: 'SegoeUI-Bold',
                        }}
                        onChange={(event) => {
                            const value = Number(event.target.value);
                            resetNotifications([
                                {
                                    questionId: row.id,
                                    isSubquestion: row.isSubQuestion,
                                },
                            ]);
                            handleQuestionStatusChange(value, row.id);
                            event.target.style.color =
                                QuestionProgressStatusList.find(
                                    (x) => x.id == value
                                ).color;
                        }}
                        name="participating status"
                        className="form-control">
                        {QuestionProgressStatusList.map((item, index) => (
                            <option
                                style={{ color: item.color }}
                                key={index + item.id + item.name}
                                value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                );
            },
        },
        {
            dataField: 'displayQuestion',
            text: '',
            style: { width: props.isAdminView ? '40%' : '50%' },
            headerClasses: 'display-question',
            classes: 'question',
            sort: false,
            formatter: (
                cellContent: string,
                row: FormQuestionsView,
                index: number
            ) => {
                const questionSeenStatus = props.questionsSeenStatus.find(
                    (f) => f.id === row.id && !f.isSubQuestion
                );

                const isQuestionNew =
                    !questionSeenStatus?.isSeenByUser &&
                    !questionSeenStatus?.isNewProject &&
                    !questionSeenStatus?.isNewForm;

                return (
                    <div className="text-answer">
                        <div className="item-question-wrapper">
                            <div>
                                {index + 1}.{' '}
                                {row.parentDisplayName?.trim() ? (
                                    <i>{`${row.parentDisplayName}: `}</i>
                                ) : (
                                    <></>
                                )}
                                {row.tableViewId != null && row.tableViewId > 0
                                    ? getStringWithLink(
                                          cellContent,
                                          row.tableViewId
                                      )
                                    : cellContent}
                            </div>
                            {!props.isAdminView && isQuestionNew ? (
                                <Badge className="unread-comments-badge">
                                    {'1'}
                                </Badge>
                            ) : (
                                []
                            )}
                        </div>

                        {renderAnswerField(row)}
                        {renderSubQuestionList(row.subQuestions, isQuestionNew)}
                    </div>
                );
            },
        },
        {
            dataField: 'userQuestion',
            text: 'Questions',
            sort: false,
            classes: 'feedback',
            formatter: (cellContent: string, row: DataFormQuestion) => {
                const questionSeenStatus = props.questionsSeenStatus.find(
                    (f) => f.id === row.id && !f.isSubQuestion
                );

                return props.isAdminView ? (
                    <div className="expanded-text-with-badge">
                        <ExpandedText
                            uniqueId={`user-question-row-${row.id}`}
                            content={cellContent}
                            heightToShowLinksPx={mediumTextAreaHeightPx}
                        />
                        {questionSeenStatus?.isUserQuestionHighlighted ? (
                            <Badge className="unread-comments-badge">
                                {'1'}
                            </Badge>
                        ) : (
                            []
                        )}
                    </div>
                ) : (
                    <Input
                        style={{ overflow: 'hidden' }}
                        onKeyUp={(element) => {
                            extendTextAreaHeight(element, cellContent);
                        }}
                        onFocusCapture={(element) => {
                            extendTextAreaHeight(element, cellContent);
                        }}
                        type="textarea"
                        onFocus={() => setUserQuestion(cellContent)}
                        onChange={(e) => {
                            setUserQuestion(e.target.value);
                        }}
                        defaultValue={cellContent}
                        onBlur={(element) => {
                            setSelectedQuestionId(row.id);
                            setFieldBlurTrigger(true);
                            decreaseTextAreaHeight(element);
                        }}></Input>
                );
            },
        },
        {
            dataField: 'adminFeedback',
            text: 'Vet Value Comments',
            sort: false,
            classes: 'feedback',
            formatter: (cellContent: string, row: DataFormQuestion) => {
                const questionSeenStatus = props.questionsSeenStatus.find(
                    (f) => f.id === row.id && !f.isSubQuestion
                );

                return props.isAdminView ? (
                    <Input
                        style={{ overflow: 'hidden' }}
                        onKeyUp={(element) => {
                            extendTextAreaHeight(element, cellContent);
                        }}
                        onFocusCapture={(element) => {
                            extendTextAreaHeight(element, cellContent);
                        }}
                        type="textarea"
                        onFocus={() => setAdminFeedback(cellContent)}
                        onChange={(e) => {
                            setAdminFeedback(e.target.value);
                        }}
                        defaultValue={cellContent}
                        onBlur={(element) => {
                            setSelectedQuestionId(row.id);
                            setFieldBlurTrigger(true);
                            decreaseTextAreaHeight(element);
                        }}></Input>
                ) : (
                    <div className="expanded-text-with-badge">
                        <ExpandedText
                            uniqueId={`admin-comment-row-${row.id}`}
                            content={cellContent}
                            heightToShowLinksPx={
                                mediumTextAreaHeightPx
                            }></ExpandedText>
                        {questionSeenStatus?.isAdminFeedbackHighlighted &&
                        !questionSeenStatus?.isNewProject &&
                        !questionSeenStatus?.isNewForm ? (
                            <Badge className="unread-comments-badge">
                                {'1'}
                            </Badge>
                        ) : (
                            <></>
                        )}
                    </div>
                );
            },
        },
        {
            dataField: 'priority',
            text: 'Priority',
            sort: true,
        },
        {
            dataField: 'dueDate',
            text: 'Due Date',
            sort: true,
            formatter: (cellContent: Date) => moment(cellContent).format('L'),
        },
        {
            dataField: 'status',
            text: 'Status',
            hidden: props.isAdminView,
            sort: true,
            formatter: (cellContent: QuestionProgressStatus) => {
                return (
                    <div
                        style={{
                            color: QuestionProgressStatusList.find(
                                (x) => x.id == cellContent
                            ).color,
                            fontFamily: 'SegoeUI-Bold',
                        }}>
                        {
                            QuestionProgressStatusList.find(
                                (x) => x.id == cellContent
                            ).name
                        }
                    </div>
                );
            },
        },
        {
            dataField: 'isMarkedAsComplete',
            text: 'Mark as complete',
            sort: false,
            hidden: props.isAdminView,
            headerClasses: 'completness-checkbox-header',
            formatter: (cellContent: boolean, row: DataFormQuestion) => (
                <div className="checkbox-field">
                    <Input
                        checked={cellContent}
                        type="checkbox"
                        onChange={(event) => {
                            resetNotifications([
                                {
                                    questionId: row.id,
                                    isSubquestion: row.isSubQuestion,
                                },
                            ]);
                            handleCompleteStatusChange(
                                event.target.checked,
                                row.id
                            );
                        }}
                        className="checkbox-input complete-checkbox"
                    />
                </div>
            ),
        },
    ];

    const getDataTableFormPopupHeader = (tableViewId: number) => {
        const { dataTables } = props.project;
        const selectedTable = dataTables.find((table) =>
            table.views.some((view) => view.id === tableViewId)
        );
        const selectedTableView = selectedTable.views.find(
            (x) => x.id === tableViewId
        );
        return selectedTable.name + ' - ' + selectedTableView.name;
    };

    const getDataTableByTableViewId = (tableViewId: number) => {
        const { dataTables } = props.project;
        const selectedTable = dataTables.find((table) =>
            table.views.some((view) => view.id === tableViewId)
        );
        return selectedTable;
    };

    return (
        <div className="questionnaire-form">
            <BootstrapTable
                striped
                bordered
                bootstrap4
                remote
                keyField="id"
                data={tableData}
                columns={getColumns()}
                onTableChange={handleTableChange}
                noDataIndication="No Data available"
            />
            {isTableViewPopupVisible && (
                <DataTableFormPopup
                    selectedDataTable={getDataTableByTableViewId(
                        selectedTableViewId
                    )}
                    selectedTableViewId={selectedTableViewId}
                    project={props.project}
                    header={getDataTableFormPopupHeader(selectedTableViewId)}
                    isAdminView={props.isAdminView}
                    triggerBack={() => {
                        setIsTableViewPopupVisible(false);
                    }}
                    updateProjectsData={props.updateProjectsData}
                    isReadonlyMode={false}
                />
            )}
        </div>
    );
};

export default QuestionnaireForm;
