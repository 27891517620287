import { DateTimeHelper } from '../../utils/dateTimeHelper';
import Answer from './Answer';
import { DataFormType } from './DataFormTypeEnum';
import { ConditionValue, ItemDisplayCondition } from './ItemDisplayCondition';
import {
    AnswerTypeVariable,
    AnswerTypeVariableNamesList,
} from './ProjectVariable';
import { QuestionProgressStatus } from './QuestionProgressStatusEnum';

export default interface DataFormQuestion extends ItemDisplayCondition {
    id: number;
    dataFormType: DataFormType;
    displayName: string;
    description: string;
    storageFolder: string;
    displayFileFormat: string;
    allowedFileFormat: string;
    adminFeedback: string;
    userQuestion: string;
    isAdminFeedbackHighlighted: boolean;
    isUserQuestionHighlighted: boolean;
    likelySource: string;
    displayOrder: number;
    isVisible: boolean;
    isMarkedAsComplete: boolean;
    isSeenByUser: boolean;
    isSeenByAdmin: boolean;
    answer?: Answer;
    gridId?: number;
    status: QuestionProgressStatus;
    frequency: string;
    datePeriodStarted: Date;
    datePeriodEnded: Date;
    parentDisplayName: string;
    displayQuestion: string;
    tableViewId: number;
    duePeriod: string;
    dueDate: Date;
    priority: string;
    displayModule: string;
    answerType: string;
    maxTextSize: string;
    displayTextSize: string;
    formType: string;
    formList: string;
    isSubQuestion: boolean;
    customQuestionId: string;
    originQuestionId: number;
    parentQuestionId: string;
    displayOrderSub: number;
}

export const isQuestionVisibleByLogic = (
    formQuestions: DataFormQuestion[],
    question: DataFormQuestion
): boolean => {
    if (question?.isDisplayLogicEnabled) {
        const parentQuestion = formQuestions?.find(
            (y) => y.customQuestionId === question.logicParentCustomId
        );

        const parentAnswerType = AnswerTypeVariableNamesList.find(
            (f) => f.name === parentQuestion?.answerType
        )?.id;

        const doesAnswerMatchCondition = isConditionFullFilled(
            question.conditionValues,
            parentQuestion?.answer?.answerText,
            parentAnswerType
        );

        if (
            parentQuestion?.answer?.answerText &&
            doesAnswerMatchCondition &&
            parentQuestion?.isVisible
        ) {
            return isQuestionVisibleByLogic(formQuestions, parentQuestion);
        }
        return false;
    }

    return true;
};

export const isConditionFullFilled = (
    childConditionValues: ConditionValue[],
    parentAnswer: string,
    answerType: AnswerTypeVariable
): boolean => {
    const isConditionsFullFilled = childConditionValues.some(
        (conditionValue) => {
            if (answerType === AnswerTypeVariable.Number) {
                return Number(conditionValue.data) === Number(parentAnswer);
            }

            if (answerType === AnswerTypeVariable.Date) {
                const areDatesEqual = DateTimeHelper.areDatesEqualIgnoringTime(
                    conditionValue.data,
                    parentAnswer
                );

                return areDatesEqual;
            }

            return conditionValue.data === parentAnswer;
        }
    );

    return isConditionsFullFilled;
};
